<template>
  <div
    v-if="!videoMetaDataLoading"
    class="video-controls"
  >
    <!-- Left Block   -->
    <div class="video-progress-text">
      {{ playedVideoDuration }} / {{ totalVideoDuration }}
    </div>

    <!-- Center block -->
    <div class="play-pause-rewind-block">
      <div class="media-control"
           v-b-tooltip="{
             variant: 'light',
             placement: 'bottom',
             customClass: 'tooltip__compact',
             title:  staticText.moveToPrevBlockTooltip,
             trigger: 'hover',
           }"
      >
        <button
          class="media-control-btn"
          @click="jumpToTargetBlock('prev')"
        >
          <img src="@/assets/svgs/backward-block-arrow.svg" svg-inline/>
        </button>
      </div>
      <div class="media-control"
           v-b-tooltip="{
             variant: 'light',
             placement: 'bottom',
             customClass: 'tooltip__compact',
             title:  staticText.jumpBackwardTooltip,
             trigger: 'hover',
           }"
      >
        <button
          class="media-control-btn"
          @click="jumpBackward"
        >
          <img src="@/assets/svgs/backward-jump.svg" svg-inline/>
        </button>
      </div>
      <div class="media-control"
           v-b-tooltip="{
             variant: 'light',
             placement: 'bottom',
             customClass: 'tooltip__compact',
             title:  isVideoPlaying ? staticText.videoPauseLabel : staticText.videoPlayLabel,
             trigger: 'hover',
           }"
      >
        <button
          class="media-control-btn play-pause-btn"
          @click="togglePlayPause"
        >
          <img v-if="isVideoPlaying" src="@/assets/svgs/pause-icon.svg" svg-inline/>
          <img v-else src="@/assets/svgs/play-icon.svg" svg-inline/>
        </button>
      </div>
      <div class="media-control"
           v-b-tooltip="{
             variant: 'light',
             placement: 'bottom',
             customClass: 'tooltip__compact',
             title:  staticText.jumpForwardTooltip,
             trigger: 'hover',
           }"
      >
        <button
          class="media-control-btn"
          @click="jumpForward"
        >
          <img src="@/assets/svgs/forward-jump.svg" svg-inline/>
        </button>
      </div>
      <div class="media-control"
           v-b-tooltip="{
             variant: 'light',
             placement: 'bottom',
             customClass: 'tooltip__compact',
             title:  staticText.moveToNextBlockTooltip,
             trigger: 'hover',
           }"
      >
        <button
          id="forward-btn-202411291147"
          class="media-control-btn"
          @click="jumpToTargetBlock('next')"
        >
          <img src="@/assets/svgs/forward-block-arrow.svg" svg-inline/>
        </button>
      </div>
    </div>

    <!-- Right block   -->
    <div class="control-blocks-right">
      <div class="speed-jump-block">
        <b-form-group
          class="position-relative"
          v-b-tooltip="{
            variant: 'light',
            placement: 'bottom',
            customClass: 'tooltip__compact',
            title:  staticText.speedTooltip,
            trigger: 'hover',
          }"
        >
          <b-form-select
            ref="videoSpeedSelect"
            id="videospeed232323"
            v-model="videoPlaybackRate"
            class="h-100 custom-form-select"
            plain
            :options="videoPlaybackRateList"
            @change="changeVideoPlaybackRate"
          >
          </b-form-select>
          <img
            src="@/assets/svgs/speedometer.svg"
            class="speedometer-icon"
            alt="Playback Speed"
            @click="triggerSelect"
          />
        </b-form-group>
      </div>

      <!-- Subtitle toggle button  -->
      <div class="subtitle-toggle ml-2"
           v-b-tooltip="{
             variant: 'light',
             placement: 'bottom',
             customClass: 'tooltip__compact',
             title:  staticText.subtitleTooltip,
             trigger: 'hover',
           }"
      >
        <div class="subtitle-toggle__btn"
             role="button"
             id="subtitle-toggle-btn-202411211450"
             @click="$emit('subtitle-toggle', !showSubtitle)"
        >
          <span class="mr-1">{{ subtitleToggleLabel }}</span>
          <img src="@/assets/svgs/subtitle-icon.svg"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "VideoControls",
  data () {
    return {
      videoPlaybackRate: 1,
      videoPlaybackRateList: [
        { value: 0.5, text: "0.5x" },
        { value: 1, text: "1x" },
        { value: 1.25, text: "1.25x" },
        { value: 1.5, text: "1.5x" },
        { value: 1.75, text: "1.75x" },
        { value: 2, text: "2x" }
      ],
      JUMP_DURATION: 15, // 15 seconds,
      staticTextDefault: {
        videoPauseLabel: "pause",
        videoPlayLabel: "play",
        moveToPrevBlockTooltip: "move to previous block",
        moveToNextBlockTooltip: "move to next block",
        jumpBackwardTooltip: "15 seconds backward",
        jumpForwardTooltip: "15 seconds forward",
        onLabel: "On",
        offLabel: "Off",
        subtitleTooltip: "Subtitles",
        speedTooltip: "Speed"
      }
    }
  },
  props: {
    showSubtitle: {
      type: Boolean,
      default: true
    },
    videoMetaDataLoading: {
      type: Boolean
    },
    isVideoPlaying: {
      type: Boolean
    },
    totalDuration: {
      type: Number
    },
    currentVideoTime: {
      type: Number
    }
  },
  computed: {
    ...mapGetters({
      meetingTranscription: "callVideoStore/getMeetingTranscript"
    }),
    subtitleToggleLabel () {
      return this.showSubtitle ? this.staticText.onLabel : this.staticText.offLabel
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    playedVideoDuration () {
      return this.formatTime(this.currentVideoTime)
    },
    totalVideoDuration () {
      return this.formatTime(this.totalDuration)
    }
  },
  methods: {
    formatTime (seconds) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const remainingSeconds = Math.floor(seconds % 60)
      return `${hours.toString().padStart(2, "")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
    },
    changeVideoPlaybackRate () {
      this.$emit("change-video-playback-rate", this.videoPlaybackRate)
    },
    togglePlayPause () {
      this.$emit("toggle-play-pause")
    },
    jumpBackward () {
      this.$emit("jump-backward", this.JUMP_DURATION)
    },
    jumpForward () {
      this.$emit("jump-forward", this.JUMP_DURATION)
    },
    jumpToTargetBlock (type) {
      this.$emit("jump-to-target-block", type)
    },
    convertTimestampToSeconds (timestamp) {
      const [minutes, seconds] = timestamp.split(":").map(Number)
      return (minutes * 60) + seconds
    },
    triggerSelect () {
      this.$refs.videoSpeedSelect.$el.showPicker()
    }
  }
}
</script>

<style lang="scss" scoped>

.video-controls {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;

  .play-pause-rewind-block {
    grid-area: 1 / 1 / 2 / 8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .media-control {
      display: flex;
      align-items: center;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .play-pause-btn {
      width: 48px;
      height: 48px;

      &:hover {
        svg {
          path {
            fill: #FF5632;
          }
        }
      }
    }
  }

  .video-progress-text {
    grid-area: 2 / 1 / 3 / 4;
    align-self: center;
    color: #555775;
    line-height: 14px;
  }

  .control-blocks-right {
    grid-area: 2 / 3 / 3 / 8;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .speed-jump-block {

      :deep(.form-group) {
        margin-bottom: 0;
      }

      .custom-form-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        box-shadow: none;
        background-color: #EFEFEF;
        color: #555775;
        padding: 7px 7px 7px 25px !important;
        line-height: 14px;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
      }

      .speedometer-icon {
        position: absolute;
        left: 8px;
        height: 12px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        pointer-events: auto;
      }
    }

    .subtitle-toggle {
      width: 65px;
      height: 28px;
      display: flex;
      justify-content: end;

      &__btn {
        background: #555775;
        display: flex;
        align-items: center;
        border-radius: 100px;
        padding: 4px 8px;
        color: $grey;
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .play-pause-rewind-block {
      justify-content: center;
      margin: 0;
    }
  }
}

</style>
