<template>
  <div class="copy-to-clipboard-container">
    <b-btn
      ref="copy-to-clipboard-btn"
      id="copy-to-clipboard-btn"
      @click="copyText(textToCopy, shouldInitiateCopyProcess)"
      class="copy-to-clipboard-button"
    >
      <img src="/img/icons/copy-icon.svg"/>
      {{ staticText.copyLabel }}
    </b-btn>
    <transition name="animate-up">
      <div
        v-if="showIsCopiedMessage"
        class="copiedLabel"
      >
        {{ staticText.copiedLabel }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "BaoCopyButton",
  data () {
    return {
      showIsCopiedMessage: false,
      staticTextDefault: {
        copyLabel: "Copy",
        copiedLabel: "Copied"
      }
    }
  },
  props: {
    textToCopy: {
      type: String,
      default: ""
    },
    shouldInitiateCopyProcess: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    async copyText (textToCopy, shouldInitiateCopyProcess = false) {
      if (shouldInitiateCopyProcess) this.$emit("initiate-copy-process")
      else {
        if (textToCopy) {
          this.$copyText(textToCopy, this.$refs["copy-to-clipboard-btn"]).then(async () => {
            await this.flashCopiedMessage()
          })
        }
      }
    },
    async flashCopiedMessage () {
      this.showIsCopiedMessage = true
      await this.sleep(1000)
      this.showIsCopiedMessage = false
    }
  }
}
</script>

<style lang="scss" scoped>

.copy-to-clipboard-container {
  display: flex;
  position: relative;
  align-items: center;

  button.copy-to-clipboard-button {
    padding: 4px 6px;
    display: flex;
    align-items: center;
    font-size: 12px;
    border-radius: 16px;
    margin-left: 8px;
    img {
      height: 12px;
      margin-right: 2px;
    }
  }

  .copiedLabel {
    position: absolute;
    background-color: $white;
    padding: 4px 8px;
    z-index: 9999;
    border-radius: 6px;
    bottom: calc(100% + 4px);
  }
}

</style>
