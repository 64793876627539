<template>
  <div>
    <div class="search-bar">
      <b-form-input
        v-model="searchQuery"
        :placeholder="staticText.searchPlaceholder"
        class="shadow-sm"
        @input="performSearch"
      ></b-form-input>
      <span class="search-icon">
        <img src="../../../../../../public/img/icons/search-icon.svg"/>
      </span>
      <span class="search-label">
        {{ staticText.searchLabel }}
      </span>
      <span
        class="cancel-icon"
        v-if="searchQuery"
        @click="clearSearch">
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="a" fill="#fff"><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z"/></mask><path d="M7 .705L6.295 0 3.5 2.795.705 0 0 .705 2.795 3.5 0 6.295.705 7 3.5 4.205 6.295 7 7 6.295 4.205 3.5 7 .705z" fill="#000" fill-opacity=".5"/><path d="M7 .705l.707.707.707-.707-.707-.707L7 .705zM6.295 0l.707-.707-.707-.707-.707.707.707.707zM3.5 2.795l-.707.707.707.707.707-.707-.707-.707zM.705 0l.707-.707-.707-.707-.707.707L.705 0zM0 .705l-.707-.707-.707.707.707.707L0 .705zM2.795 3.5l.707.707.707-.707-.707-.707-.707.707zM0 6.295l-.707-.707-.707.707.707.707L0 6.295zM.705 7l-.707.707.707.707.707-.707L.705 7zM3.5 4.205l.707-.707L3.5 2.79l-.707.707.707.707zM6.295 7l-.707.707.707.707.707-.707L6.295 7zM7 6.295l.707.707.707-.707-.707-.707L7 6.295zM4.205 3.5l-.707-.707-.707.707.707.707.707-.707zM7.707-.002l-.705-.705L5.588.707l.705.705L7.707-.002zm-2.12-.705L2.794 2.088l1.414 1.414L7.002.707 5.588-.707zm-1.38 2.795L1.412-.707-.002.707l2.795 2.795 1.414-1.414zM-.003-.707l-.704.705L.707 1.412l.705-.705L-.002-.707zm-.704 2.12l2.795 2.794 1.414-1.414L.707-.002-.707 1.412zm2.795 1.38L-.707 5.588.707 7.002l2.795-2.795-1.414-1.414zm-2.795 4.21l.705.704 1.414-1.414-.705-.705-1.414 1.414zm2.12.704l2.794-2.795-1.414-1.414-2.795 2.795 1.414 1.414zm1.38-2.795l2.795 2.795 1.414-1.414-2.795-2.795-1.414 1.414zm4.21 2.795l.704-.705-1.414-1.414-.705.705 1.414 1.414zm.704-2.12L4.912 2.794 3.498 4.207l2.795 2.795 1.414-1.414zm-2.795-1.38l2.795-2.795L6.293-.002 3.498 2.793l1.414 1.414z" fill="#2A2D52" fill-opacity=".01" mask="url(#a)"/></svg>
      </span>
    </div>
    <hr class="my-auto">
    <div class="transcripts-container" ref="transcriptsContainer">
      <div
        v-for="(entry, index) in filteredTranscripts"
        :key="index"
        :class="['transcript-entry', {'active': index === lastActiveIndex}]"
        @click="seekTo(entry.rawTimestamp)"
      >
        <span class="timestamp-entry">{{ entry.Timestamp }}</span>
        <span class="speaker-entry">{{ entry.Speaker }}</span>
        <span class="sentence-entry" v-html="highlightText(entry.Sentence)"></span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BaoVideoTranscript",
  props: {
    meetingTranscript: Array,
    currentTime: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      lastActiveIndex: -1, // Stores the last active index
      processedTranscripts: [],
      searchQuery: "",
      filteredTranscripts: [],
      staticTextDefault: {
        searchPlaceholder: "Type to search.",
        searchLabel: "Search"
      }
    }
  },
  computed: {
    processedAndFilteredTranscripts () {
      return this.searchQuery
        ? this.filteredTranscripts
        : this.processedTranscripts
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    processTranscripts () {
      this.processedTranscripts = this.meetingTranscript.map(entry => ({
        ...entry,
        timeInSeconds: this.convertToSeconds(entry.Timestamp),
        rawTimestamp: entry.Timestamp
      }))
    },
    seekTo (timestamp) {
      const timeInSeconds = this.convertToSeconds(timestamp)
      if (Number.isFinite(timeInSeconds)) {
        this.$emit("update-time", timeInSeconds)
      } else {
        console.error("Invalid time value for seekTo:", timeInSeconds)
      }
    },
    convertToSeconds (timestamp) {
      if (typeof timestamp === "number") {
        return timestamp
      }
      const parts = timestamp.split(":").map(Number)
      let seconds = 0
      if (parts.length === 3) {
        seconds = parts[0] * 3600 + parts[1] * 60 + parts[2]
      } else if (parts.length === 2) {
        seconds = parts[0] * 60 + parts[1]
      } else if (parts.length === 1) {
        seconds = parts[0]
      }
      return seconds
    },
    findActiveIndex () {
      const currentTimeInSeconds = this.convertToSeconds(this.currentTime)
      const lastIndex = this.processedAndFilteredTranscripts.length - 1
      this.lastActiveIndex = -1

      for (let i = 0; i <= lastIndex; i++) {
        const nextTimeInSeconds = i < lastIndex ? this.processedAndFilteredTranscripts[i + 1].timeInSeconds : Infinity
        if (currentTimeInSeconds >= this.processedAndFilteredTranscripts[i].timeInSeconds && currentTimeInSeconds < nextTimeInSeconds) {
          this.lastActiveIndex = i
          break
        }
      }
    },
    scrollToActive () {
      this.$nextTick(() => {
        const container = this.$refs.transcriptsContainer
        if (container) {
          const activeElement = container.querySelector(".transcript-entry.active")
          if (activeElement) {
            container.scrollTop = activeElement.offsetTop - 8 // 8px padding
          }
        }
      })
    },
    performSearch () {
      if (this.searchQuery) {
        this.filteredTranscripts = this.processedTranscripts.filter(entry =>
          entry.Sentence.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      } else {
        this.filteredTranscripts = this.processedTranscripts
      }
    },
    clearSearch () {
      this.searchQuery = ""
      this.filteredTranscripts = this.processedTranscripts
    },
    highlightText (text) {
      if (!this.searchQuery) return text
      const regex = new RegExp(`(${this.escapeRegExp(this.searchQuery)})`, "gi")
      return text.replace(regex, "<span class='query-mark'>$1</span>")
    },
    escapeRegExp (string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    }
  },
  watch: {
    currentTime () {
      this.findActiveIndex()
      this.scrollToActive()
    },
    meetingTranscript: {
      handler () {
        this.processTranscripts()
        this.performSearch()
      },
      immediate: true
    },
    searchQuery () {
      this.performSearch()
    }
  },
  mounted () {
    this.scrollToActive()
  }
}
</script>

<style scoped lang="scss">
  .transcripts-container {
    max-height: 300px;
    position: relative;
    overflow-y: scroll;
  }

  .transcript-entry {
    padding: 8px;
    border-radius: 8px;
    margin: 2px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 3fr 8fr;
  }

  .transcript-entry.active {
    background-color: #F0E7DF;
  }

  .search-bar {
    position: relative;
    max-width: 200px;

    .search-label {
      top: 2px;
      color: $slate40;
      position: absolute;
      left: 16px;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      font-size: 11px;
    }

    input:not(:placeholder-shown) ~ .search-label {
      bottom: 100%;
      opacity: 1;
      visibility: visible;
    }

    input {
      font-size: 12px;
      padding: 4px 32px;
      height: 44px;
      background-color: $white40;
      box-shadow: 14px 12px 40px rgba(199, 199, 199, 0.12);
      border: none;
      border-radius: 12px;
    }

    .search-icon {
      top: 0;
      height: 100%;
      color: $slate40;
      position: absolute;
      display: flex;
      align-items: center;
      left: 16px;

      img {
        height: 14px;
        width: 14px;
      }
    }

    .cancel-icon {
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 16px;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.3s ease-in;

      svg {
        height: 7px;
        width: 7px;
      }
    }
  }
</style>
