<template>
  <div
    v-if="!!videoUrl"
    class="video-container"
  >
    <video
      :src="videoUrl"
      class="video-player"
      controls
      disablepictureinpicture
      controlsList="nodownload noplaybackrate"
      id="meeting-video"
      @play="startUpdatingTime"
      @pause="stopUpdatingTime"
      @seeked="handleTimeUpdate"
      ref="meetingVideo"
    ></video>
    <VideoSubtitle
      v-show="showSubtitle"
      :currentTime="currentVideoTime"
    />
    <div class="video-controls-container">
      <video-controls
        ref="video-controls"
        :isVideoPlaying="isVideoPlaying"
        :video-meta-data-loading="videoMetaDataLoading"
        :totalDuration="totalDuration"
        :currentVideoTime="currentVideoTime"
        :show-subtitle="showSubtitle"
        @toggle-play-pause="togglePlayPause"
        @jump-backward="jumpBackward"
        @jump-forward="jumpForward"
        @jump-to-target-block="jumpToTargetBlock"
        @change-video-playback-rate="changeVideoPlaybackRate"
        @subtitle-toggle="value =>showSubtitle = value"
      />
      <speaker-timeline
        v-if="speakerTimelineBlocksData"
        ref="speaker-timeline"
        :video-meta-data-loading="videoMetaDataLoading"
        :video-progress="videoProgress"
        :speaker-timeline-blocks-data="speakerTimelineBlocksData"
        @update-video-progress="updateVideoProgress"
      />
    </div>

    <div class="top-right-style">
      <meeting-participants/>

      <div
        v-if="!isOpenFromExternalCallShare"
        class="share-video ml-3"
        @click="shareBaoVideo"
      >
        <img id="share-bao-video-btn" src="@/assets/svgs/share-icon.svg" svg-inline/>
        <b-tooltip
          target="share-bao-video-btn"
          :title="isVideoCopied ? staticText.videoSharedAlertLabel : staticText.shareVideoLabel"
          :delay="{ show: 100, hide: 400 }"
          triggers="hover"
        ></b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import MeetingParticipants from "./MeetingParticipants"
import VideoControls from "./VideoControls.vue"
import SpeakerTimeline from "./SpeakerTimeline.vue"
import SpeakerTimelineClass from "@/apps/call/speakerTimeline"
import { mapGetters } from "vuex"
import VideoSubtitle
  from "@/apps/call_history/CallFeedbackComponents/BaoAudioAndVideo/BaoVideoComponents/VideoSubtitle.vue"

export default {
  name: "BaoVideoPlayer",
  components: {
    MeetingParticipants,
    VideoControls,
    SpeakerTimeline,
    VideoSubtitle
  },
  data () {
    return {
      meetingParticipants: [],
      animationFrameId: null,
      isVideoCopied: false,
      videoProgressPercentage: 0,
      videoMetaDataLoading: true,
      totalDuration: 1,
      currentVideoTime: 0,
      isVideoPlaying: false,
      pollingFrame: null,
      speakerTimeline: null,
      speakerTimelineBlocksData: null,
      showSubtitle: true,
      staticTextDefault: {
        videoSharedAlertLabel: "Link copied",
        shareVideoLabel: "Copy Link"
      }
    }
  },
  props: {
    isOpenFromExternalCallShare: {
      default: false
    }
  },
  mounted () {
    this.handleVideoEvents()
    this.speakerTimeline = new SpeakerTimelineClass()
  },
  computed: {
    ...mapGetters({
      videoUrl: "callVideoStore/getVideoUrl",
      speakerTimelineData: "callVideoStore/getSpeakerTimeline"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    videoProgress () {
      return this.videoProgressPercentage
    }
  },
  methods: {
    handleVideoEvents () {
      const videoElement = document.getElementById("meeting-video")

      videoElement.addEventListener("timeupdate", () => {
        const progress = (videoElement.currentTime / videoElement.duration) * 100
        this.videoProgressPercentage = progress
      })

      videoElement.addEventListener("loadedmetadata", async () => {
        this.totalDuration = videoElement.duration
        this.videoMetaDataLoading = false
        // setup speaker timeline
        this.setupSpeakerTimeline(this.totalDuration)
        // wait for some time post the setup timeline for video progress element to load
        await this.sleep(500)
        if (this.$refs["speaker-timeline"]) this.$refs["speaker-timeline"].handleVideoProgressEvents(videoElement)
        this.startVideoProgressPolling()
      })

      videoElement.addEventListener("seeked", () => {
        const progress = (videoElement.currentTime / videoElement.duration) * 100
        this.videoProgressPercentage = progress
      })
      // handle play/pause events
      videoElement.addEventListener("play", () => {
        this.isVideoPlaying = true
      })
      videoElement.addEventListener("pause", () => {
        this.isVideoPlaying = false
      })
    },
    setupSpeakerTimeline (totalDuration) {
      this.speakerTimeline.setup(totalDuration, this.speakerTimelineData)
      this.speakerTimelineBlocksData = this.speakerTimeline.getSpeakerTimelineSpeakerBlocks()
    },
    updateVideoProgress (progressPercent) {
      const videoElement = document.getElementById("meeting-video")
      this.videoProgressPercentage = progressPercent * 100
      videoElement.currentTime = progressPercent * videoElement.duration
    },
    async startVideoProgressPolling () {
      const pollProgressFunc = () => {
        const videoElement = document.getElementById("meeting-video")
        const progress = (videoElement.currentTime / videoElement.duration) * 100
        this.videoProgressPercentage = progress
        this.currentVideoTime = videoElement.currentTime
        this.pollingFrame = requestAnimationFrame(pollProgressFunc)
      }
      pollProgressFunc()
    },
    changeVideoPlaybackRate (newPlaybackRate) {
      const videoElement = document.getElementById("meeting-video")
      videoElement.playbackRate = newPlaybackRate
    },
    togglePlayPause () {
      const videoElement = document.getElementById("meeting-video")
      if (this.isVideoPlaying) {
        videoElement.pause()
        this.isVideoPlaying = false
      } else {
        videoElement.play()
        this.isVideoPlaying = true
      }
    },
    jumpBackward (jumpDistance) {
      const videoElement = document.getElementById("meeting-video")
      videoElement.currentTime = Math.max(0, videoElement.currentTime - jumpDistance)
    },
    jumpForward (jumpDistance) {
      const videoElement = document.getElementById("meeting-video")
      videoElement.currentTime = Math.min(videoElement.duration, videoElement.currentTime + jumpDistance)
    },
    jumpToTargetBlock (type) {
      const videoElement = document.getElementById("meeting-video")
      const targetBlock = this.speakerTimeline.findTargetBlock(videoElement.currentTime, type)
      videoElement.currentTime = targetBlock.startTime
    },
    shareBaoVideo () {
      this.$copyText(window.location.href).then(async () => {
        this.isVideoCopied = true
        await this.sleep(2000)
        this.isVideoCopied = false
      })
    },
    handleTimeUpdate () {
      const currentTime = Math.floor(this.$refs.meetingVideo.currentTime)
      this.$emit("video-time-changed", currentTime)
    },
    startUpdatingTime () {
      const update = () => {
        this.handleTimeUpdate()
        this.animationFrameId = requestAnimationFrame(update)
      }
      update()
    },
    stopUpdatingTime () {
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId)
        this.animationFrameId = null
      }
    },
    seekTo (timeInSeconds) {
      this.$refs.meetingVideo.currentTime = timeInSeconds
    },
    stopPolling () {
      cancelAnimationFrame(this.pollingFrame)
    }
  },
  beforeDestroy () {
    this.stopUpdatingTime()
    this.stopPolling()
  }
}
</script>

<style lang="scss" scoped>

.video-container {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
}

.video-player {
  width: 100% !important;
  border-radius: 16px 16px 0 0;
  max-height: 580px;
}

.video-controls-container {
  background: $white;
  padding: 16px;
  border-radius: 0 0 16px 16px;
}

.video-player::-webkit-media-controls-play-button,
.video-player::-webkit-media-controls-current-time-display,
.video-player::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

.top-right-style {
  position: absolute;
  z-index: 2;
  top: 16px;
  right: 16px;
  display: flex;
}

.share-video {
  cursor: pointer;
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
  }
}
</style>
