var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.videoMetaDataLoading)?_c('div',{staticClass:"video-controls"},[_c('div',{staticClass:"video-progress-text"},[_vm._v(" "+_vm._s(_vm.playedVideoDuration)+" / "+_vm._s(_vm.totalVideoDuration)+" ")]),_c('div',{staticClass:"play-pause-rewind-block"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
           variant: 'light',
           placement: 'bottom',
           customClass: 'tooltip__compact',
           title:  _vm.staticText.moveToPrevBlockTooltip,
           trigger: 'hover',
         }),expression:"{\n           variant: 'light',\n           placement: 'bottom',\n           customClass: 'tooltip__compact',\n           title:  staticText.moveToPrevBlockTooltip,\n           trigger: 'hover',\n         }"}],staticClass:"media-control"},[_c('button',{staticClass:"media-control-btn",on:{"click":function($event){return _vm.jumpToTargetBlock('prev')}}},[_c('img',{attrs:{"src":require("@/assets/svgs/backward-block-arrow.svg"),"svg-inline":""}})])]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
           variant: 'light',
           placement: 'bottom',
           customClass: 'tooltip__compact',
           title:  _vm.staticText.jumpBackwardTooltip,
           trigger: 'hover',
         }),expression:"{\n           variant: 'light',\n           placement: 'bottom',\n           customClass: 'tooltip__compact',\n           title:  staticText.jumpBackwardTooltip,\n           trigger: 'hover',\n         }"}],staticClass:"media-control"},[_c('button',{staticClass:"media-control-btn",on:{"click":_vm.jumpBackward}},[_c('img',{attrs:{"src":require("@/assets/svgs/backward-jump.svg"),"svg-inline":""}})])]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
           variant: 'light',
           placement: 'bottom',
           customClass: 'tooltip__compact',
           title:  _vm.isVideoPlaying ? _vm.staticText.videoPauseLabel : _vm.staticText.videoPlayLabel,
           trigger: 'hover',
         }),expression:"{\n           variant: 'light',\n           placement: 'bottom',\n           customClass: 'tooltip__compact',\n           title:  isVideoPlaying ? staticText.videoPauseLabel : staticText.videoPlayLabel,\n           trigger: 'hover',\n         }"}],staticClass:"media-control"},[_c('button',{staticClass:"media-control-btn play-pause-btn",on:{"click":_vm.togglePlayPause}},[(_vm.isVideoPlaying)?_c('img',{attrs:{"src":require("@/assets/svgs/pause-icon.svg"),"svg-inline":""}}):_c('img',{attrs:{"src":require("@/assets/svgs/play-icon.svg"),"svg-inline":""}})])]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
           variant: 'light',
           placement: 'bottom',
           customClass: 'tooltip__compact',
           title:  _vm.staticText.jumpForwardTooltip,
           trigger: 'hover',
         }),expression:"{\n           variant: 'light',\n           placement: 'bottom',\n           customClass: 'tooltip__compact',\n           title:  staticText.jumpForwardTooltip,\n           trigger: 'hover',\n         }"}],staticClass:"media-control"},[_c('button',{staticClass:"media-control-btn",on:{"click":_vm.jumpForward}},[_c('img',{attrs:{"src":require("@/assets/svgs/forward-jump.svg"),"svg-inline":""}})])]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
           variant: 'light',
           placement: 'bottom',
           customClass: 'tooltip__compact',
           title:  _vm.staticText.moveToNextBlockTooltip,
           trigger: 'hover',
         }),expression:"{\n           variant: 'light',\n           placement: 'bottom',\n           customClass: 'tooltip__compact',\n           title:  staticText.moveToNextBlockTooltip,\n           trigger: 'hover',\n         }"}],staticClass:"media-control"},[_c('button',{staticClass:"media-control-btn",attrs:{"id":"forward-btn-202411291147"},on:{"click":function($event){return _vm.jumpToTargetBlock('next')}}},[_c('img',{attrs:{"src":require("@/assets/svgs/forward-block-arrow.svg"),"svg-inline":""}})])])]),_c('div',{staticClass:"control-blocks-right"},[_c('div',{staticClass:"speed-jump-block"},[_c('b-form-group',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
          variant: 'light',
          placement: 'bottom',
          customClass: 'tooltip__compact',
          title:  _vm.staticText.speedTooltip,
          trigger: 'hover',
        }),expression:"{\n          variant: 'light',\n          placement: 'bottom',\n          customClass: 'tooltip__compact',\n          title:  staticText.speedTooltip,\n          trigger: 'hover',\n        }"}],staticClass:"position-relative"},[_c('b-form-select',{ref:"videoSpeedSelect",staticClass:"h-100 custom-form-select",attrs:{"id":"videospeed232323","plain":"","options":_vm.videoPlaybackRateList},on:{"change":_vm.changeVideoPlaybackRate},model:{value:(_vm.videoPlaybackRate),callback:function ($$v) {_vm.videoPlaybackRate=$$v},expression:"videoPlaybackRate"}}),_c('img',{staticClass:"speedometer-icon",attrs:{"src":require("@/assets/svgs/speedometer.svg"),"alt":"Playback Speed"},on:{"click":_vm.triggerSelect}})],1)],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
           variant: 'light',
           placement: 'bottom',
           customClass: 'tooltip__compact',
           title:  _vm.staticText.subtitleTooltip,
           trigger: 'hover',
         }),expression:"{\n           variant: 'light',\n           placement: 'bottom',\n           customClass: 'tooltip__compact',\n           title:  staticText.subtitleTooltip,\n           trigger: 'hover',\n         }"}],staticClass:"subtitle-toggle ml-2"},[_c('div',{staticClass:"subtitle-toggle__btn",attrs:{"role":"button","id":"subtitle-toggle-btn-202411211450"},on:{"click":function($event){return _vm.$emit('subtitle-toggle', !_vm.showSubtitle)}}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.subtitleToggleLabel))]),_c('img',{attrs:{"src":require("@/assets/svgs/subtitle-icon.svg")}})])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }