<template>
  <div class="d-flex flex-column justify-content-center py-3 no-data-style">
    <div class="d-flex justify-content-center pb-2">
      <div class="in-process-spinner"></div>
    </div>
    {{ processingMessage }}
  </div>
</template>

<script>
export default {
  name: "ProcessSpinner",
  props: {
    processingMessage: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
  .no-data-style {
    background-color: #FFF;
    border-radius: 16px;
    text-align: center;
    color: #AAABBA;
  }

  .in-process-spinner {
    width: 18px;
    height: 18px;
    position: relative;
    top: 1px;
    left: 18.88px;
    border: 3px solid transparent;
    border-radius: 50%;
    opacity: 0.8;
    box-sizing: border-box;
    background: #35375B;
    -webkit-mask:
      conic-gradient(from 90deg at 50% 50%, #35375B 0deg, rgba(53, 55, 91, 0) 309.38deg, rgba(53, 55, 91, 0) 360deg),
      linear-gradient(#000 0 0) content-box;
    mask:
      conic-gradient(from 90deg at 50% 50%, #35375B 0deg, rgba(53, 55, 91, 0) 309.38deg, rgba(53, 55, 91, 0) 360deg),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: rotateSpinner 2s linear infinite;
  }

  @keyframes rotateSpinner {
    to {
      transform: rotate(360deg);
    }
  }
</style>
