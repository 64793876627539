<template>
  <div class="video-subtitle">
    <span class="video-subtitle__content" v-html="currentSubtitle"></span>
  </div>
</template>
<script>
import { mapGetters } from "vuex"

const TOTAL_CHARACTERS_TO_DISPLAY = 120

export default {
  name: "VideoSubtitle",
  props: {
    currentTime: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      words: "callVideoStore/getWordsWithTimestamp"
    }),
    getGroupedSubtitles () {
      // Group words into subtitle phrases with a maximum of 100 characters
      const groupedSubtitles = []
      let group = { words: [], start: this.words[0].start_timestamp, end: null, length: 0 }

      this.words.forEach((word, index) => {
        const wordLength = word.text.length

        if (group.length + wordLength + 1 <= TOTAL_CHARACTERS_TO_DISPLAY) {
          // Add the word to the current group
          group.words.push(word)
          group.end = word.end_timestamp
          group.length += wordLength + 1 // Include space
        } else {
          // Finalize the current group and start a new one
          groupedSubtitles.push(group)
          group = { words: [word], start: word.start_timestamp, end: word.end_timestamp, length: wordLength }
        }
      })

      // Push the last group if it exists
      if (group.words.length > 0) groupedSubtitles.push(group)
      return groupedSubtitles
    },

    currentSubtitle () {
      // Find the current subtitle group
      const currentSubtitle = this.getGroupedSubtitles.find(
        group => this.currentTime >= group.start && this.currentTime <= group.end
      )
      if (currentSubtitle) {
        // Build the subtitle with highlighted word
        return currentSubtitle.words.map(word => {
          if (this.currentTime >= word.start_timestamp && this.currentTime <= word.end_timestamp) {
            return `<span class="query-mark">${word.text}</span>`
          } else {
            return word.text
          }
        }).join(" ")
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">
.video-subtitle {
  position: static;
  margin-top: -68px;
  height: 68px;
  text-align: -webkit-center;

  &__content {
    border-radius: 2px;
    text-align: center;
    padding-inline: 6px;
    color: #fff;
    background: #0f0f0f;
    opacity: 0.7;
    width: fit-content;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

</style>
