<template>
  <div>
    <!-- Summary action buttons/ dropdown -->
    <div class="mb-3">
      <div
        v-if="!isOpenFromExternalCallShare"
        class="d-flex summary-block"
      >
        <button
          v-for="summaryType in summaryVariants"
          :key="summaryType.key"
          class="summary-button"
          :class="{'active-summary': summaryType.key==currentSummaryTab}"
          @click="getSummaryData(summaryType.key)"
        >
          {{summaryType.title}}
        </button>
      </div>
      <div
        v-else
        class="summary-dropdown"
      >
        <inner-label-dropdown
          :dropdownItems="summaryVariantsWithSummary"
          :label="staticText.summaryTypeLabel"
          :placeholder="staticText.summaryTypeLabel"
          :value="summaryVariantsWithSummary[0] || null"
          :allowEmpty="false"
          dropdownLabel="title"
          track-by="key"
          @input="(summaryType) => getSummaryData(summaryType.key)"
        />
      </div>
    </div>
    <!-- Summary details -->
    <div
      v-if="!!currentActiveSummary"
      ref="transcriptSummary"
      :class="['mb-2 summary-container', { 'no-scroll' : isOpenFromExternalCallShare }]"
    >
      <div v-if="isBulletPointSummary">
        <ul class="bullet-point-summary">
          <li
            v-for="(line, index) in currentActiveSummary"
            :key="index"
          >
            {{line}}
          </li>
        </ul>
      </div>
      <div v-else-if="isTopicBlockSummary">
        <div
          v-for="(summary, summaryIndex) in currentActiveSummary"
          :key="'summary-' + summaryIndex"
        >
          <h6>{{ summary.title }} ({{ summary.time_range }})</h6>
          <ul>
            <li
              v-for="(detail, index) in summary.details"
              :key="'detail-' + summaryIndex + '-' + index"
            >
              {{ detail }}
            </li>
          </ul>
        </div>
      </div>
      <div v-else-if="isActionItemsSummary">
        <ul>
          <li v-for="(summary, summaryIndex) in currentActiveSummary" :key="'summary-' + summaryIndex">
            <h6>{{ summary.title }}</h6>
            <p>{{ summary.details }}</p>
          </li>
        </ul>
      </div>
      <div v-else-if="isEmailSummary">
        <h6>{{ currentActiveSummary.subject }}</h6>
        <p v-for="(emailBody, emailBodyIndex) in currentActiveSummary.body" :key="'emailBody-' + emailBodyIndex">
          {{ emailBody }}
        </p>
      </div>
      <div v-else>
        {{ currentActiveSummary }}
      </div>

      <!-- Summary disclaimer -->
      <div class="summary-disclaimer">
        {{ staticText.summaryDisclaimer }}
      </div>
    </div>
    <div v-else>
      {{ staticText.noSummaryAvailableText }}
    </div>
  </div>
</template>

<script>
import { SUMMARY_TYPES } from "@/apps/call_history/CallFeedbackComponents/constants"
import { mapActions, mapGetters } from "vuex"
import { setCookie, getCookie } from "@/utils/utils"
import InnerLabelDropdown from "@/apps/base/InnerLabelDropdown.vue"

export default {
  name: "BaoVideoSummary",
  components: {
    InnerLabelDropdown
  },
  data () {
    return {
      currentSummaryTab: "default",
      staticTextDefault: {
        noSummaryAvailableText: "Sadly no summary available for this call.",
        defaultSummaryLabel: "Default",
        shortSummaryLabel: "Short",
        longSummaryLabel: "Long",
        bulletpointsSummaryLabel: "Bulletpoints",
        topicBlocksSummaryLabel: "Topic Blocks",
        actionItemsSummaryLabel: "Action Items",
        emailSummaryLabel: "E-Mail",
        summaryDisclaimer: "This summary was computer generated and might contain errors.",
        summaryTypeLabel: "Summary type"
      },
      SUMMARY_TYPES
    }
  },
  props: {
    isOpenFromExternalCallShare: {
      default: false
    }
  },
  mounted () {
    this.setupSummaryView()
  },
  computed: {
    ...mapGetters({
      isMeetingEnded: "callVideoStore/isMeetingEnded",
      meetingSummary: "callVideoStore/getMeetingSummary"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    summaryVariants () {
      return [
        {
          title: this.staticText.defaultSummaryLabel,
          key: "default"
        },
        {
          title: this.staticText.shortSummaryLabel,
          key: "short"
        },
        {
          title: this.staticText.longSummaryLabel,
          key: "long"
        },
        {
          title: this.staticText.bulletpointsSummaryLabel,
          key: "bulletpoints"
        },
        {
          title: this.staticText.topicBlocksSummaryLabel,
          key: "topic_blocks"
        },
        {
          title: this.staticText.actionItemsSummaryLabel,
          key: "action_items"
        },
        {
          title: this.staticText.emailSummaryLabel,
          key: "email"
        }
      ]
    },
    summaryVariantsWithSummary () {
      return this.summaryVariants.filter(summaryVariant => this.doesSummaryExist(summaryVariant))
    },
    currentActiveSummary () {
      return this.meetingSummary[this.currentSummaryTab]
    },
    isBulletPointSummary () {
      return this.currentSummaryTab === this.SUMMARY_TYPES.BULLETPOINTS
    },
    isTopicBlockSummary () {
      return this.currentSummaryTab === this.SUMMARY_TYPES.TOPIC_BLOCKS
    },
    isActionItemsSummary () {
      return this.currentSummaryTab === this.SUMMARY_TYPES.ACTION_ITEMS
    },
    isEmailSummary () {
      return this.currentSummaryTab === this.SUMMARY_TYPES.EMAIL
    }
  },
  methods: {
    ...mapActions({
      setCurrentSummaryText: "callSummaryStore/setCurrentSummaryText"
    }),
    setCurrentSummaryTabFromCookie () {
      const favoriteSummaryFormat = getCookie("favoriteSummaryFormat")
      this.currentSummaryTab = favoriteSummaryFormat || "default"
    },
    getSummaryData (summaryType) {
      // Set the "favorite" summary format to clicked tab
      setCookie("favoriteSummaryFormat", summaryType)
      this.currentSummaryTab = summaryType
      this.setCurrentSummary()
    },
    setupSummaryView () {
      if (!this.isOpenFromExternalCallShare) this.setCurrentSummaryTabFromCookie()
      else this.currentSummaryTab = this.summaryVariantsWithSummary[0].key || null
    },
    async copyTranscriptSummary () {
      const transcriptSummary = this.$refs.transcriptSummary

      if (!transcriptSummary) {
        console.error("Transcript summary element not found")
        return
      }

      try {
        const range = document.createRange()
        range.selectNodeContents(transcriptSummary)
        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)

        const fragment = range.cloneContents()
        const div = document.createElement("div")
        div.appendChild(fragment)

        const { ClipboardItem } = window
        await navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([div.innerHTML], { type: "text/html" }),
            "text/plain": new Blob([transcriptSummary.innerText], { type: "text/plain" })
          })
        ])
        // Display the "copied" popup
        this.$emit("show-copy-success")
      } catch (err) {
        console.error("Failed to copy content with format", err)
        const textToCopy = this.$refs.transcriptSummary.innerText
        this.$emit("copy-text", textToCopy)
      } finally {
        const selection = window.getSelection()
        selection.removeAllRanges()
      }
    },
    async setCurrentSummary () {
      await this.sleep(100)
      const transcriptSummary = this.$refs.transcriptSummary
      const summaryText = transcriptSummary ? transcriptSummary.innerText : ""
      this.setCurrentSummaryText({ summaryText })
    },
    doesSummaryExist (summaryVariant) {
      // depending on the format of the summary, it checks if there's summary available or not
      const meetingSummary = this.meetingSummary[summaryVariant.key]
      if (!meetingSummary) return false
      if (typeof meetingSummary === "string" || (typeof meetingSummary === "object" && Array.isArray(meetingSummary))) return meetingSummary.length
      else if (typeof meetingSummary === "object" && !Array.isArray(meetingSummary)) return Object.keys(meetingSummary).length
      return false
    }
  }
}
</script>

<style lang="scss" scoped>

.summary-block{
  overflow-x: auto;
}

.summary-dropdown {
  width: 100%;
  @include media-breakpoint-up(sm) {
    width: 300px;
  }
}

.summary-button {
  padding: 9px 10px;
  border-radius: 12px;
  line-height:14px;
  justify-content: center;
  font-size: 12px;
  align-items: center;
  background-color: $slate06;
  outline: none;
  border: none;
  color: $slate80;
  height: fit-content;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
}

.active-summary {
  background-color: #FF56321F !important;
  color: #FF5632 !important;
}

.summary-container {
  color: #00000080;
  overflow-y: scroll;
  max-height: 300px;

  &.no-scroll {
    max-height: unset;
  }
}

.bullet-point-summary {
  padding-left: 16px;
  margin-bottom: 0;
}

.summary-disclaimer {
  color: $slate40;
  font-size: 12px;
}

</style>
